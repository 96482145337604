export class AlertModel {
    templateReqRelId:number;
    comment:string;
    processTemplateReqRelId:number;
    processId :number;
    userMail:string;
    templateTypeName:string;
    partyName:string;
    userName:string;
     
}
