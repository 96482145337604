import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { AcceptRejectDraft } from 'src/app/shared/models/accept-reject-draft';
import { AcceptRejectNegotiation } from 'src/app/shared/models/accept-reject-negotiation';
import { Draft } from 'src/app/shared/models/draft';
import { DraftService } from 'src/app/shared/services/draft.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PendingDraftApprovalComponent } from '../pending-draft-approval/pending-draft-approval.component';

@Component({
  selector: 'kt-accept-reject-draft',
  templateUrl: './accept-reject-draft.component.html',
  styleUrls: ['./accept-reject-draft.component.scss']
})
export class AcceptRejectDraftComponent implements OnInit {
status : boolean;
@ViewChild('appDashboard', { static: false }) appDashboard: PendingDraftApprovalComponent;
  constructor(public  dialogRef:  MatDialogRef<AcceptRejectDraftComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  any,
    private draftServive : DraftService,
    private toastr: ToastrService,
    private router: Router,
    private tokenStorageService :TokenStorageService,
    private eventEmitterService: EventEmitterService,
    public loader: LoaderService,
    ) {
 }
 form: AcceptRejectDraft = new AcceptRejectDraft();
 fileExtention;
 fileUrl;
 ngOnInit(): void {
  this.form.processTemplateReqRelId = this.data.processTemReqId;
  this.form.processId = this.data.processId;
  this.form.userMail = this.tokenStorageService.getUser().email;
  this.form.userId = this.tokenStorageService.getUser().id;
  this.form.templateTypeName = this.data.documentTypeName;
  this.form.status = this.data.status;
  this.form.partyName = this.data.partyName;
  this.form.templateRequestedId = this.data.templateRequestedId;
  this.form.templateDataId = this.data.templateDataId;
  this.form.templateTypeName = this.data.templateTypeName
  this.status = (this.data.status =='accept')? true : false;
}
onFileSelect(event):void{
	if(event.target.files.length > 0){
	  const file = event.target.files[0];
	   this.form.file = file;
	   var ext = file.name.split('.').pop();
      this.fileExtention = ext;
	  var reader = new FileReader();
	  reader.readAsDataURL(event.target.files[0]);

	  reader.onload = (_event) => {
		   this.fileUrl = reader.result;
	  }
	}

  }
confirm(){

  // if(this.status == false &&  this.form.file ==  null )
  // {
  // this.toastr.error("Please choose a vaild word or pdf document. 😟" , "Error!");
  // return;
  // }

  if(this.form.comment==undefined || this.form.comment== null )
  {
          this.toastr.error("Your comment is required.😟" , "Error!");
           return;
  }


  if(this.validateDocument()){
    this.toastr.info("Please Wait.... 😀" , "Information");
    this.loader.show();
    //this.eventEmitterService.sendClickEvent();
    this.draftServive.PostDraftCommericalApproval(this.form , this.status).subscribe(res =>{
      this.loader.hide();
    this.toastr.success("Draft confirmed successfully 😀" , "Success!");
    //window.location.reload();

    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['draft/pending-draft-approvals']);
    });

    //this.eventEmitterService.sendClickEvent();
   // this.router.navigateByUrl('/draft/pending-draft-approvals');
    
  },error=>{
    this.loader.hide();
    this.toastr.error("Failed to confirm Draft 😟" , "Error!");
    this.router.navigateByUrl('/draft/pending-draft-approvals');
  });
  this.dialogRef.close();
}
else{
  this.toastr.error("Plaese Upload Document 😟" , "Error!");
}
}
CloseDialog()
{
  this.dialogRef.close();
}
validateDocument(){
  if(this.fileExtention === "docx" || "doc" ){
    return true;
  }
  else{
    this.toastr.error("file should be only word document 😲");
    return false;

  }
}
}
