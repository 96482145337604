<!-- <div class="deleted-dialog">
  <h2>Do you want to delete this item ?</h2>
  <button mat-button (click)="confirm()">Yes</button>
  <button mat-button (click)="cancel()">No</button>
</div> -->

<div class="card">
  <div class="card-header">
    <h1>Delete Document</h1>
  </div>
  <div class="card-body">
    <div style="color: red; font-size: 24px; text-align: center; padding: 5px; margin: 3px">Do you want to Delete this Document ?</div>
    <div>
      <button type="button" class="btn btn-danger btns m-3 p-3" (click)="confirm()">Delete</button>
      <button type="button" class="btn btn-success btns btnsSide p-3" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
