import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user.model';
import { StagingBaseURL } from './global/global-config';
// tslint:disable-next-line:whitespace
const userMangmentURL = environment.contratoApiUrl+'UserManagement/GetAllUsers';
const managersURL = environment.contratoApiUrl+'UserManagement/GetAllManagers';
const addUserURL = environment.contratoApiUrl+'UserManagement/AddUser';
const getUserURL = environment.contratoApiUrl+'UserManagement/GetUser';
const editUserURL = environment.contratoApiUrl+'UserManagement/EditUser';
const getUserForRolesURL = environment.contratoApiUrl+'UserManagement/GetUserForRoles';
const GetAllUsersToFollowUpURL = environment.contratoApiUrl+'UserManagement/GetAllUsersToFollowUp';
const GetUserNotificationCountURL = environment.contratoApiUrl+'UserManagement/GetUserNotificationCount';
const GetUserNotificationsURL = environment.contratoApiUrl+'UserManagement/GetUserNotifications';
const SeenNotificationURL = environment.contratoApiUrl+'UserManagement/NotificationSeen';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class UserManagmentService {

  constructor(private http: HttpClient) { }
  GetAllUsers() {
    return this.http.get<User[]>(userMangmentURL,httpOptions);
  }
  GetUserForRoles(id:number){
    return this.http.get<User[]>(getUserForRolesURL+`/${id}`);
  }
  GetAllManagers(): Observable<any> {
    return this.http.get(managersURL,httpOptions);
  }
  AddUser(user: User): Observable<any> {
    return this.http.post(addUserURL, {
      name: user.name,
      email : user.email,
       userName: user.userName,
       roles:user.roles,
       departmentFk: user.department,
       sendingAlert:user.sendingAlert,
       isSupervisor: user.isSupervisor,
       isActive:user.isActive,
       isAccountManager:user.isAccountManager,
       managerId:user.manager,
       ActionTypes:user.ActionTypes,
       companies : user.companies
    },httpOptions);
  }
  EditUser(user: User): Observable<any> {
    return this.http.post(editUserURL, {
      id: user.id,
      name: user.name,
      email : user.email,
      userName: user.userName,
       roles:user.roles ,
       departmentFk: user.department,
       sendingAlert:user.sendingAlert,
       isSupervisor: user.isSupervisor,
       isActive:user.isActive,
       isAccountManager:user.isAccountManager,
       managerId:user.manager,
       companies :user.companies,
       password:user.password,
       isInitializer:user.isInitializer,
       signaturePath:user.signaturePath,
       fileName:user.fileName,
       type:user.type
    },httpOptions);
  }
  GetUser(id: number): Observable<any> {
    // tslint:disable-next-line:whitespace
    return this.http.get(getUserURL+`/${id}`);
  }

  UserSessionStorageObj()
  {
  return JSON.parse(localStorage.getItem('auth-user'));
  }
  GetAllUsersToFollowUp(id:number){
    return this.http.get<User[]>(GetAllUsersToFollowUpURL+`/${id}`);
  }
  GetUserNotificationCount(id: number): Observable<any> {
    // tslint:disable-next-line:whitespace
    return this.http.get(GetUserNotificationCountURL+`/${id}`);
  }
  GetUserNotifications(id: number): Observable<any> {
    // tslint:disable-next-line:whitespace
    return this.http.get(GetUserNotificationsURL+`/${id}`);
  }
  NotificationSeen(notificationId: number) {
  return this.http.post<any>(SeenNotificationURL+`?notificationId=${notificationId}`,httpOptions);

  }
}
