export class RolesTable {
  public static roles: any = [
    {
      id: 1,
      title: 'Requester',
      isCoreRole: true,
      permissions: [3]
    },
    {
      id: 2,
      title: 'Legal Account Manager',
      isCoreRole: false,
      permissions: [5]
    },
    {
      id: 3,
      title: 'Supervisor',
      isCoreRole: false,
      permissions: [2]
    },
    {
      id: 4,
      title: 'Manage Approval',
      isCoreRole: false,
      permissions: [6]
    },
    {
      id: 5,
      title: 'Budget Approval',
      isCoreRole: false,
      permissions: [4]
    },
    {
      id: 6,
      title: 'Archiving',
      isCoreRole: false,
      permissions: [7]
    },
    {
      id: 7,
      title: 'Reporting',
      isCoreRole: false,
      permissions: [8]
    },
    {
      id: 8,
      title: 'KPI Reporting',
      isCoreRole: false,
      permissions: [9]
    }
  ];
}
