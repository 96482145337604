import { Process } from "src/app/shared/models/process";
import { User } from "../auth/_models/user.model";

export class MenuConfig {
  constructor(private user: User, private process: Process) { }
  public _menudefaults: any = {
    header: {
      self: {},
      // items: [
      //   {
      //     title:'Draft',
      //     root:true,
      //     alignment: 'left',
      //     toggle: 'click',
      //     permission : 'accessToDraftModule',
      //     submenu:[
      //       {
      //         title: 'New Draft',
      //         page: '/draft/add-draft-request',
      //         icon: 'flaticon-add',
      //         permission: 'accessToDraftModule'
      //       },
      //       {
      //         title: 'Pending Drafts',
      //         page: '/draft/draft-request',
      //         icon: 'flaticon-share',
      //         permission: 'accessToDraftModule'
      //       },
      //     ]
      //   },

      // ]
    },
    aside: {
      self: {},
      items: [
        // {section: 'Search'},
        {
          title: "Search",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-info",
          submenu: [
            {
              title: "Free Search",
              page: "/search/global-serach",
              icon: "flaticon-search-1",
            },
            {
              title: "Advanced Search",
              page: "/search/advanced-serach",
              icon: "flaticon-search-1",
            },
          ],
        },
        {
          sectiom: "parties",
        },
        {
          title: "Parties",
          root: true,
          bullet: "dot",
          icon: "flaticon2-browser-2",
          // permission : 'accessToPartyModule',
          submenu: [
            {
              title: "Parties Data",
              page: "/party/parties",
              icon: "flaticon-search-1",
              // permission: 'accessToPartyModule'
            },
            {
              title: "Add Party",
              page: "/party/newParty",
              icon: "flaticon-search-1",
              // permission: 'accessToDraftModule'
            },
            {
              title: "Add Entity",
              page: "/party/party-entity",
              icon: "flaticon-search-1",
              // permission: 'accessToDraftModule'
            },
            {
              title: "Add Operator",
              page: "/party/party-operator",
              icon: "flaticon-search-1",
              // permission: 'accessToDraftModule'
            },
          ],
        },
        //  {section: 'Managmnent'},
        {
          title: "User Management",
          root: true,
          bullet: "dot",
          icon: "flaticon2-user-outline-symbol",
          permission: "accessToUserManagmentModule",
          submenu: [
            {
              title: "Users",
              page: "/user-management/Users",
              permission: "accessToUserManagmentModule",
            },
          ],
        },
        {
          title: "User Profile",
          root: true,
          bullet: "dot",
          icon: "flaticon2-user-outline-symbol",
          submenu: [
            {
              title: "Notification Rule",
              page: "/user-profile/userprofile",
            },
          ],
        },
        {
          title: "KPI",
          root: true,
          bullet: "dot",
          icon: "flaticon2-hourglass-1",
          permission: "accessToKPIModule",
          submenu: [
            {
              title: "Contrato KPI",
              page: "/legal-KPI/legalkpi",
              permission: "accessToKPIModule",
            },
            {
              title: "Legal KPI",
              page: "/legal-KPI/categorizaedLegalKpi",
              permission: "accessToKPIModule",
            },
          ],
        },
        //  {section: 'Settings'},
        {
          title: "Settings",
          root: true,
          bullet: "dot",
          icon: "flaticon-settings-1",
          permission: "accessToUserManagmentModule",
          submenu: [
            {
              title: "Templates Data",
              page: "/setting/templates-data",
              permission: "accessToUserManagmentModule",
            },
            {
              title: "Templates Types",
              page: "/setting/templates-types",
              permission: "accessToUserManagmentModule",
            },
          ],
        },
        // {section: 'Draft'},
        {
          title: "Draft",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-file-1",
          permission: "accessToDraftModule",
          submenu: [
            {
              title: "New Draft",
              page: "/draft/add-draft-request",
              icon: "flaticon-add",
              permission: "accessToDraftModule",
            },
            {
              title: "Pending Drafts",
              page: "/draft/draft-request",
              icon: "flaticon-share",
              permission: "accessToDraftModule",
            },
            // pending-draft-approvals
            {
              title: "Drafts Approval",
              page: "/draft/pending-draft-approvals",
              icon: "	flaticon-interface-5",
              permission: "accessToDraftModule",
            },
            {
              title: "Follow Up Drafts",
              page: "/draft/FollowMyDrafts",
              icon: "flaticon-statistics",
              permission: "accessToDraftModule",
            },
          ],
        },
        // {section: 'Negotiation'},
        {
          title: "Negotiation",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-light",
          permission: "accessToNegotiationModule",
          submenu: [
            {
              title: "All Pending Negotiations",
              page: "/negotiation/pending-negotiations",
              icon: "	flaticon-information",
              permission: "accessToNegotiationModule",
            },
          ],
        },
        // {section: 'Business Cases'},
        {
          title: "Business Cases",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-edit",
          permission: "accessToBusinessCaseModule",
          submenu: [
            {
              title: "All Business Cases",
              page: "/businessCase/all-business-cases",
              icon: "flaticon-search-1",
              permission: "accessToBusinessCaseModule",
            },
          ],
        },
        // {section: 'Approval'},
        {
          title: "Approval",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-calendar-2",
          // permission : 'accessToApprovalModule',
          submenu: [
            {
              title: "Search Approvals ",
              page: "/Approval/SearchApprovals",
              icon: "flaticon-search-1",
              permission: "accessToApprovalModule",
            },
            {
              title: "Pending On Me",
              page: "/Approval/PendingOnMe",
              icon: "flaticon-search-1",
              // permission: 'accessToApprovalModule'
            },
          ],
        },

        // {section: 'Archiving'},
        {
          title: "Archiving",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-interface-9",
          permission: "accessToArchivingModule",
          submenu: [
            {
              title: "Archiving",
              page: "/Archiving/AddArchivedContract",
              icon: "flaticon-add-circular-button",
              permission: "accessToArchivingModule",
            },
          ],
        },
        // {section: 'Reporting'},
        {
          title: "Reporting",
          root: true,
          alignment: "left",
          toggle: "click",
          icon: "flaticon-line-graph",
          permission: "accessToReportingModule",
          submenu: [
            {
              title: "Processes Data",
              page: "/reporting/processes-data",
              icon: "flaticon-statistics",
              permission: "accessToReportingModule",
            },
          ],
        },
      ],
    },
  };
  public get configs(): any {
    // debugger
    // if(this.user != undefined)
    // {
    //   if(this.process != undefined )
    //   {
    //     if(this.process.id != 0)
    //     {
    //     return this._menuContinueDraft;
    //     }
    //   }
    // }
    return this._menudefaults;
  }
}
