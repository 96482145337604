import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { AlertModel } from 'src/app/shared/models/alert-model';
import { ApprovalService } from 'src/app/shared/services/approval.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'kt-alert-legal',
  templateUrl: './alert-legal.component.html',
  styleUrls: ['./alert-legal.component.scss']
})
export class AlertLegalComponent implements OnInit {

  constructor(
    public  dialogRef:  MatDialogRef<AlertLegalComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  any,
    private toastr: ToastrService,
    public sharedService:SharedService,
    private router: Router,
    private approvalService:ApprovalService,
    private tokenStorageService:TokenStorageService

  ) { }
  form: AlertModel = new AlertModel();

  ngOnInit(): void {
    this.form.processTemplateReqRelId = this.data.processTemReqId;
    this.form.processId = this.data.processId;
    this.form.userName = this.tokenStorageService.getUser().name;
    this.form.partyName = this.data.partyName;
    this.form.templateTypeName = this.data.documentTypeName;
  }


  SendAlert(){
    this.toastr.info("Please wait a while" , "Sending....");
    //this.sharedService.sendClickEvent();
      this.approvalService.SendingAlert(this.form).subscribe(res =>{
      this.toastr.success("Message sent successfully 😀" , "Success!");
      this.router.navigate(['Approval/PendingOnMe']);

    },error=>{
      this.toastr.error("Failed to send the Message 😟" , "Error!");
     // this.router.navigate(['Approval/PendingOnMe']);
    });
    this.dialogRef.close();
   
  }

  CloseDialog()
{
  this.dialogRef.close();
}

}
