
export const environment = {
  production: false,
  staging: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',

  // ----------------- Staging ------------------------------------
 contratoApiUrl:'https://contratostagingapi.arpuplus.com/api/',
  staticFilesUrl:'https://contratostagingapi.arpuplus.com/',
  archivingStaticFilesUrl:'https://contratostaging.arpuplus.com/storage/',
  WebsiteUrl: 'https://contratostaging.arpuplus.com',
  clientId: 'e97d6769-3386-4ba8-a254-f96e9b535e6b',
  signalRUrl:'https://contratostagingapi.arpuplus.com/inform',
  password:"@@XX@@##Ct"

};
