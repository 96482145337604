<div class="card">
  <div class="card-header">
    <h1>Reject Negotation</h1>
  </div>
  <div class="card-body">

    <form class="form" name="form" (ngSubmit)="f.form.valid && confirm()" #f="ngForm" novalidate>
      <div class="form-group">
        <div class="row">
          <div class="form-group col-12">
            <label for="comment">Comment</label>
            <textarea class="form-control" required rows="3" name="comment" #comment="ngModel"
              [(ngModel)]="form.comment"></textarea>
            <div class="text-muted" style="width:100%" *ngIf="f.submitted && !form.comment">
              Comment is required!
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <mat-form-field appearance="fill">
              <mat-label>Rejection reason</mat-label>
              <ng-container *ngIf="form.isFastTrack && !form.ToNoramlTrack, else elseBlock">
                <mat-select name="nextStep" #nextStep="ngModel" [(ngModel)]="form.nextStep" required>
                  <mat-option *ngFor="let step of rejectionSteps.slice(0, 1)" [value]="step.value">
                    {{step.name}}
                  </mat-option>
                </mat-select>
              </ng-container>

              <ng-template #elseBlock>
                <mat-select name="nextStep" #nextStep="ngModel" [(ngModel)]="form.nextStep" required>
                  <mat-option *ngFor="let step of rejectionSteps" [value]="step.value">
                    {{step.name}}
                  </mat-option>
                </mat-select>
              </ng-template>
            </mat-form-field>
          </div>
        </div>

        <!-- Adding Check Box to Change the Draft To Normal Track -->
        <div class="row" *ngIf="form.isFastTrack">
          <div class="col-12">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="ToNoramlTrack" name="ToNoramlTrack"
                [(ngModel)]="form.ToNoramlTrack" />
              <label class="form-check-label" for="flexCheckDefault"> Transfare To Noraml Track </label>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.ToNoramlTrack && form.isFastTrack">
          <div class="form-group col-12">
            <label for="comment">Transfare To Normal Comment *</label>
            <textarea class="form-control" rows="3" name="transfareComment"
              placeholder="Why you need to transfare it to Normal Track" #transfareComment="ngModel"
              [(ngModel)]="form.transfareComment"></textarea>
            <div class="text-muted" style="width:100%" *ngIf="f.submitted && !form.transfareComment">
              Comment is required!
            </div>
          </div>
        </div>


        <div class="row">
          <div class="form-group col-12">
            <label for="file">Attach File</label>
            <input type="file" class="form-control-file" name="file" placeholder="upload document"
              (change)="onFileSelect($event)">
            <!-- <div
            class="text-muted" style="width:100%"
            *ngIf="f.submitted && !form.file">
            Attached File is required!
            </div> -->
          </div>
        </div>

      </div>
      <div>
        <button type="submit" class="btn btn-success btns">Confirm</button>
        <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
      </div>
    </form>

  </div>

</div>