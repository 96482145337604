
 <div class="card">
    <div class="card-header">
      <h1>Sending Message</h1>
    </div>
    <div class="card-body">


      <div style="color: green;font-size: 17px; text-align: center;">
       We will notify the Legal by your comment.
      </div>

      <form class="form"  name="form" 
      (ngSubmit)="alert.form.valid && SendAlert()"
      #alert="ngForm"
      novalidate>
        <div class="form-group">
         


          <div class="row">
            <div class="form-group col-12">
              <label for="comment">Comment</label>
              <textarea class="form-control" rows="3" name="comment"  #comment="ngModel" [(ngModel)]="form.comment" required></textarea>
              <div
              class="text-muted" style="width:100%"
              *ngIf="alert.submitted && !form.comment">
              Comment is required!
              </div>
              </div>
          </div>
          

        </div>
<div>
  <button type="submit" class="btn btn-success btns">Send</button>
  <button type="button" class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
      </form>

    </div>

</div>


