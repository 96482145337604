// Angular
import { ChangeDetectorRef, Component } from '@angular/core';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { LayoutConfigService } from '../../../../core/_base/layout';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { UserManagmentService } from 'src/app/core/auth/_services/user-managment.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  searchDisplay = true;
  notificationsDisplay = true;
  quickActionsDisplay = true;
  cartDisplay = true;
  quickPanelDisplay = true;
  languagesDisplay = true;
  userDisplay = true;
  userLayout = 'offcanvas';
  userDropdownStyle = 'light';
  private _hubConnection: HubConnection;
  connectionId: any;
  notificationCount: any;
  constructor(private layoutConfigService: LayoutConfigService,
    private changeDetector: ChangeDetectorRef,
    private token: TokenStorageService,
    private userManagmentService: UserManagmentService,
    private router: Router,
  ) {
    this.searchDisplay = this.layoutConfigService.getConfig('extras.search.display');
    this.notificationsDisplay = this.layoutConfigService.getConfig('extras.notifications.display');
    this.quickActionsDisplay = this.layoutConfigService.getConfig('extras.quick-actions.display');
    this.cartDisplay = this.layoutConfigService.getConfig('extras.cart.display');
    this.quickPanelDisplay = this.layoutConfigService.getConfig('extras.quick-panel.display');
    this.languagesDisplay = this.layoutConfigService.getConfig('extras.languages.display');
    this.userDisplay = this.layoutConfigService.getConfig('extras.user.display');
    this.userLayout = this.layoutConfigService.getConfig('extras.user.layout');
    this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style');
  }
  ngOnInit(): void {
    this.getUserNotificationCount();
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();
    // connection.start().then(function () {
    //   console.log('Connected!');
    // }).catch(function (err) {
    //   return console.error(err);
    // });
    this._hubConnection.start().then(() => {
      // console.log('Connedddction started!');
      this.GetConnectionId();
    }).then(() => {
      this.AddUserToGroup();
    }).catch(err => console.error(err));

    this._hubConnection.on("InformClient", (message: string) => {
      this.getUserNotificationCount();
      this.reloadComponent();
      //this.playAudio();
      this.changeDetector.detectChanges();
      this.changeDetector.reattach();
    });
    this._hubConnection.on("InformGroup", (message: string) => {
      this.getUserNotificationCount();
      this.reloadComponent();
      //this.playAudio();
      this.changeDetector.detectChanges();
      this.changeDetector.reattach();
    });
  }

  GetConnectionId(): void {
    this._hubConnection.invoke('GetConnectionId').then((connection) => {
      this.connectionId = connection;
      //  console.log(this.connectionId);
      this.token.saveUserConnectionId(this.connectionId);
    });
  }
  AddUserToGroup(): void {
    let userRoles = this.token.getCurrentUser().roles;
    //Budget Approval
    if (userRoles.includes(5)) {
      this._hubConnection.invoke('AddToGroup', 'Budjet').then(() => {
      });
    }
    //Legal
    if (userRoles.includes(2)) {
      this._hubConnection.invoke('AddToGroup', 'Legal').then(() => {
      });
    }

  }
  getUserNotificationCount() {
    let currentUser = this.token.getUser().id;
    this.userManagmentService.GetUserNotificationCount(currentUser).subscribe(res => {
      if (this.notificationCount != res) {
        this.notificationCount = res;
      }
    });
  }
  playAudio() {
    // let audio = new Audio("../../../assets/audio/notification-sound.aac");
    // audio.src = "../../../assets/audio/notification-sound.aac";
    // audio.load();
    // audio.play();
    /* Audio link for notification */
    var mp3 = '<source src="../../../../../assets/audio/pristine-609.mp3">';
    document.getElementById("sound").innerHTML =
      '<audio autoplay="autoplay">' + mp3 + "</audio>";
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}

