import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA, MatDialog} from  '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { AcceptRejectBusinessCase } from 'src/app/shared/models/accept-reject-business-case';
import { DraftService } from 'src/app/shared/services/draft.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import thId from 'src/assets/plugins/formvalidation/src/js/validators/id/thId';
@Component({
  selector: 'kt-accept-business-case',
  templateUrl: './accept-business-case.component.html',
  styleUrls: ['./accept-business-case.component.scss']
})
export class AcceptBusinessCaseComponent implements OnInit {
  form: AcceptRejectBusinessCase = new AcceptRejectBusinessCase();
  constructor(public  dialogRef:  MatDialogRef<AcceptBusinessCaseComponent>,
     @Inject(MAT_DIALOG_DATA) public  data:  any,
     private draftServive : DraftService,
     private toastr: ToastrService,
     public sharedService:SharedService,
     private router: Router,
     private tokenStorageService :TokenStorageService
     ) {
  }
  ngOnInit(): void {
    this.form.processId = this.data.processId;
    this.form.processTempReqRelId = this.data.processTempReqRelId;
    this.form.amount = this.data.amount;
    this.form.userId = this.tokenStorageService.getUser().id;
    this.form.userMail = this.tokenStorageService.getUser().email;
    this.form.partyName = this.data.partyName;
    this.form.templateTypeName = this.data.templateTypeName;
  }
confirm(){
  this.sharedService.sendClickEvent();
  this.draftServive.AcceptProcess(this.form).subscribe(res =>{
    this.toastr.success("Business Case confirmed successfully 😀" , "Success!");
    this.router.navigate(['/businessCase/all-business-cases']);
  },error=>{
    this.toastr.error("Failed to confirm business case 😟" , "Error!");
  });
  this.dialogRef.close();
}
}
